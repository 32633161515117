import React, { useContext, useState } from 'react'
import './sidnavdashboard.scss'
// icons 
import { RiCloseCircleLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { MdOutlineEngineering } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { IoPeopleOutline } from "react-icons/io5";
// icons 
import logo from '../../Assets/Images/New_logo_2.png'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/Authcontext';


export default function Sidnavdashboard({ role }) {
    const [collapsed, setcollapsed] = useState(true)
    const [Hide, setHide] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const { setUserRole } = useContext(AuthContext)
    const Logout = () => {
        navigate('/admin')
    }

    const handleHide = () => {
        setHide(!Hide)
    }
    const handleICon = () => {
        setcollapsed(!collapsed)
    }
    return <>
        <div className={Hide ? "dropback apper-dropback" : "dropback"} onClick={handleHide}>
        </div>
        <aside className={Hide ? 'asideclass allnavv' : 'asideclass allnavv apper'} >
            <Sidebar collapsed={collapsed}>
                <Menu className='main-menu'>

                    <Menu className={collapsed ? 'collapsed main-side overflow-y-auto min-h-screen' :
                        'main-side p-1 overflow-y-auto min-h-screen'}>

                        <MenuItem
                            className="mb-10 rounded-3xl text-3xl mt-8 text-white cursor-pointer"
                            onClick={handleICon}
                            icon={collapsed ? <FaBars /> : <RiCloseCircleLine />}
                            component={<span className='Remove_hover'></span>}
                        ></MenuItem>

                        <span className='flex justify-center items-center my-9'>
                            <img src={logo} loading='lazy' alt="Logo" className={collapsed ? 'Logo-colaps' : 'Logo'} />
                        </span>

                        <div className="flex justify-between flex-col">
                            <div className="Links">
                                <MenuItem onClick={handleHide} className={pathname === "dashboard" ? 'menu-items  Active' : 'menu-items'} component={<Link to='/dashboard' className='Remove_hover transition ease-linear'></Link>}
                                    icon={<MdOutlineAdminPanelSettings className={pathname === "/" ? 'icon transition ease-linear Active' : 'icon transition ease-linear'} />}>
                                    Admins
                                </MenuItem>
                                <MenuItem onClick={handleHide} className={pathname === "Analysts" ? 'menu-items  Active' : 'menu-items'} component={<Link to='Analysts' className='Remove_hover transition ease-linear'></Link>}
                                    icon={<MdOutlineEngineering className={pathname === "Analysts" ? 'icon transition ease-linear Active' : 'icon transition ease-linear'} />}>
                                    Analysts
                                </MenuItem>
                                <MenuItem onClick={handleHide} className={pathname === "/Veiwer" ? 'menu-items  Active' : 'menu-items'} component={<Link to='Veiwer' className='Remove_hover transition ease-linear'></Link>}
                                    icon={<IoPeopleOutline className={pathname === "/moderatores" ? 'icon transition ease-linear Active' : 'icon transition ease-linear'} />}>
                                    Viewers
                                </MenuItem>
                            </div>
                        </div>
                        <MenuItem
                            className="rounded-3xl text-lg text-white
                            font-semibold Logout"
                            icon={<RiLogoutBoxLine />}
                            type={"button"}
                            component={<span className='Remove_hover transition ease-linear'></span>}
                            onClick={Logout}
                        >
                            Home
                        </MenuItem>
                    </Menu>

                </Menu>
            </Sidebar >
        </aside >

        <span className='bars' onClick={handleHide}>
            {Hide ? <RiCloseCircleLine /> : <FaBars />}
        </span>

    </>
}