import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ToastContainer } from 'react-toastify'
import { CookiesProvider } from 'react-cookie'
import Charts from './Context/Charts'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import '../node_modules/react-toastify/dist/ReactToastify.css'
import { Buffer } from 'buffer'
import process from 'process'
import AuthProvider from './Context/Authcontext'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

window.Buffer = Buffer
window.process = process

const root = ReactDOM.createRoot(document.getElementById('root'))
let query = new QueryClient()

root.render(
  <CookiesProvider defaultSetOptions={{ path: '/', secure: true }}>
    <QueryClientProvider client={query}>
      <AuthProvider>
        <Charts>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
          <ToastContainer position='bottom-right' />
        </Charts>
      </AuthProvider>
      <ReactQueryDevtools position='bottom-right' />
    </QueryClientProvider>
  </CookiesProvider>

)

reportWebVitals()
