import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidnavdashboard from '../../../components/SidenavforDash/Sidnavdashboard'
import "./Dashlayout.scss"
export default function Dashlayout() {
    return <div className='flex justify-start items-center bg-[#f2f0f0]'>
        <div className="sidnav">
            <Sidnavdashboard />
        </div>
        <div className="all w-full overflow-hidden">
            <Outlet>
            </Outlet>
        </div>
    </div>

}
