import { createContext, useState } from 'react'

export const AuthContext = createContext()

export default function AuthProvider ({ children }) {
  const [userRole, setUserRole] = useState(null)
  const [userId, setuserId] = useState(null)

  return <AuthContext.Provider value={{ userRole, setUserRole,userId, setuserId}}>
           {children}
         </AuthContext.Provider>
}
