import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import { lazy, Suspense, useContext, useEffect } from 'react';
import Loading from './components/Loading/Loading';
import LoginProtector from './protector/LoginProtector';

import Layout from './pages/Layout';
import Dashlayout from './pages/dashboard/DashLayout/Dashlayout';
import AuthLayout from './pages/Auth/AuthLayout';

import axios from 'axios';
import { BaseUrl } from './API/ApiHandle';
import { useCookies } from 'react-cookie';
import { ChartContext } from './Context/Charts';
import ShareLayout from './pages/Share/ShareLayout';
import ChangeEmailLayout from './pages/ChangeEmail.Layout';
import NotFound from './pages/NotFound/NotFound';
import AlertPage from './pages/AlertPage/AlertPage';
import RoleProtector from './protector/Roleprotector';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from './Context/Authcontext';
// Viewer 
const ViewerHome = lazy(() => import("./pages/Home/ViewerHome/ViewerHome"));
const ReportDetails = lazy(() => import("./pages/Home/ViewerReportDetails/ReportDetails"));

// analyst 
const Home = lazy(() => import("./pages/Home/Home"));
const SignUp = lazy(() => import("./pages/Auth/SignUp/SignUp"));
const Visualize = lazy(() => import("./pages/Visualize/Visualize"));
const Dashboard = lazy(() => import("./pages/dashboard/Admin/DashboardAdmin"));
const Analysts = lazy(() => import("./pages/dashboard/Analysts/DashboardAnalysts"));
const Veiwer = lazy(() => import("./pages/dashboard/Viewer/DashboarViewer"));

const Support = lazy(() => import("./pages/Support/Support"));
const Tables = lazy(() => import("./pages/Tables/Tables"));
const Share = lazy(() => import("./pages/Share/Share"));
const Aboutus = lazy(() => import("./pages/Aboutus/Aboutus"));

const Login = lazy(() => import("./pages/Auth/Login/Login"));
const Forget = lazy(() => import("./pages/Auth/ForgetPassword/ForgetPassword"));
const Change = lazy(() => import("./pages/Auth/ChangePassword/ChangePassword"));
const ChangeEmail = lazy(() => import('./pages/Auth/ChangeEmail/ChangeEmail'))

const Profile = lazy(() => import("./pages/Profile/Profile"));


function App() {

  const [Cookies,] = useCookies(['rqm']);
  const { setConfirmed } = useContext(ChartContext);
  const { setUserRole, userRole, setuserId } = useContext(AuthContext)

  useEffect(() => {
    const fetchUserConfirmation = async () => {
      if (!Cookies.rqm) return;
      let { role: userRole, id } = jwtDecode(Cookies.rqm);
      console.log("appjs", userRole);
      setuserId(id)
      setUserRole(userRole);
      try {
        const { data } = await axios.get(`${BaseUrl}/check_confirmation`, {
          headers: {
            Authorization: `Bearer ${Cookies.rqm}`
          }
        });
        console.log(data);

        setConfirmed(data?.confirmed)
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserConfirmation();
  }, [Cookies.rqm, userRole]);

  const router = createBrowserRouter([
    // analyst routes /////////////////
    {
      path: "", element: <Layout role="analyst" />, children: [
        {
          index: true, element:
            <LoginProtector>
              <Suspense fallback={<Loading />}>
                <RoleProtector requiredRole="analyst">
                  <Home />
                </RoleProtector>
              </Suspense>
            </LoginProtector>
        },

        {
          path: 'Tables/:id', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="analyst">
                <Tables />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Visualize/:TableId', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="analyst">
                <Visualize />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Support', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="analyst">
                <Support />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'aboutus', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <Aboutus />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: "profile", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="analyst">
                <Profile />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        }
      ],
    },

    // admin routes /////////////////
    {
      path: "admin", element: <Layout role="admin" />, children: [
        {
          index: true, element:
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Suspense fallback={<Loading />}><Home /></Suspense>
              </RoleProtector>
            </LoginProtector>
        },
        {
          path: 'Tables/:id', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Tables />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Visualize/:TableId', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Visualize />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Support', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Support />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'aboutus', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Aboutus />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: "profile", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Profile />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        }
      ],
    },


    // Viewer routes /////////////////
    {
      path: "viewer", element: <Layout role="viewer" />, children: [
        {
          index: true, element:
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <Suspense fallback={<Loading />}><ViewerHome /></Suspense>
              </RoleProtector>
            </LoginProtector>
        },
        {
          path: 'reportdetails/:report_id', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <ReportDetails />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Support', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <Support />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'aboutus', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <Aboutus />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: "profile", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="viewer">
                <Profile />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        }
      ],
    },

    // dashboard routes //////////
    {
      path: "dashboard", element: <Dashlayout />, children: [
        {
          index: true, element:
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Suspense fallback={<Loading />}><Dashboard /></Suspense>
              </RoleProtector>
            </LoginProtector>
        },
        {
          path: 'Analysts', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Analysts />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Veiwer', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <RoleProtector requiredRole="admin">
                <Veiwer />
              </RoleProtector>
            </LoginProtector>
          </Suspense>
        },
      ],
    },

    {
      path: "", element: <AuthLayout />, children: [
        { path: "Login", element: <Suspense fallback={<Loading />}><Login /></Suspense> },
        { path: "SignUp", element: <Suspense fallback={<Loading />}><SignUp /></Suspense> },
        { path: "Forget", element: <Suspense fallback={<Loading />}><Forget /></Suspense> },
        { path: "Reset/:rqm", element: <Suspense fallback={<Loading />}><Change /></Suspense> },
      ]
    },

    {
      path: "", element: <ShareLayout />, children: [
        { path: "Charts/:file_id", element: <Suspense fallback={<Loading />}><Share /></Suspense> }
      ]
    },

    {
      path: "changeEmail", element: <ChangeEmailLayout />, children: [
        {
          path: ":rqm", element: <Suspense fallback={<Loading />}>
            <ChangeEmail />
          </Suspense>
        }
      ]
    },

    {
      path: "*", element: <NotFound />,
    },

    {
      path: "Alert/:errorMessage/:userId", element: <AlertPage />
    }
  ])



  // console.clear();

  return <RouterProvider router={router}>
  </RouterProvider>
}

export default App;
