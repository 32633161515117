import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/Authcontext';
import Loading from '../components/Loading/Loading';


export default function RoleProtector({ children, requiredRole }) {

    const { userRole } = useContext(AuthContext);
    if (userRole === null || userRole === undefined) {
        return <Loading />;
    }

    return userRole === requiredRole ? children : <Navigate to="/Login" replace />;
}
