import React, { useContext, useState } from 'react'
import './HomeNave.scss'
// import { LiaTableSolid } from "react-icons/lia";
// import { CgChevronDoubleRightR } from "react-icons/cg";
import { LuFileQuestion } from "react-icons/lu";
import { GrUserAdmin } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";

import { FaBars } from "react-icons/fa";

import logo from '../../Assets/Images/New_logo_2.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import { ChartContext } from '../../Context/Charts';
import axios from 'axios';
import { BaseUrl } from '../../API/ApiHandle';



export default function HomeNave({ role }) {
    const [Hide, setHide] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [rqm, , removeCookie] = useCookies(['rqm']);
    // const { TableId } = useContext(ChartContext);

    const Logout = async () => {
        await axios.get(`${BaseUrl}/logout`, {
            headers: {
                Authorization: `Bearer ${rqm.rqm}`
            }
        })
            .then((res) => {
                localStorage.clear();
                if (res.data.Done) {
                    removeCookie('rqm', { path: '/' });
                    localStorage.clear()
                    navigate('/Login')
                }
            })
            .catch((err) => console.log(err))

    }

    const handleHide = () => {
        setHide(!Hide)
    }

    const HandleNavigate = (Link) => {
        navigate(Link);
        setHide(false)
    }
    return <>
        <div className={Hide ? "dropback apper-dropback" : "dropback "} onClick={handleHide}>
        </div>
        <aside className={Hide ? 'allnav  py-24 px-7' : 'allnav py-24 px-7 apper'} >

            <div>
                <img className="Logo-colaps" src={logo} loading='lazy' alt='Logo' />

                {role === "analyst" ? (
                    <div className="h-[300px] flex flex-col gap-y-1 mt-9">
                        <p onClick={() => HandleNavigate('/')} className={pathname === "/" ? 'Active' : ''}><IoHomeOutline className='icon' /></p>
                        <p onClick={() => HandleNavigate('/aboutus')} className={pathname === "/" ? 'Active' : ''}><LuFileQuestion className='icon' /></p>
                        <p onClick={() => HandleNavigate('/Support')} className={pathname === "/Support" ? 'Active' : ''}><BiSupport className='icon' /></p>
                    </div>
                ) : role === "viewer" ? (
                    <div className="h-[300px] flex flex-col gap-y-1 mt-9">
                        <p onClick={() => HandleNavigate('')} className={pathname === "/" ? 'Active' : ''}><IoHomeOutline className='icon' /></p>
                        <p onClick={() => HandleNavigate('aboutus')} className={pathname === "/" ? 'Active' : ''}><LuFileQuestion className='icon' /></p>
                        <p onClick={() => HandleNavigate('Support')} className={pathname === "/Support" ? 'Active' : ''}><BiSupport className='icon' /></p>
                    </div>
                ) : role === "admin" ? (<div className="h-[300px] flex flex-col gap-y-1 mt-9">
                    <p onClick={() => HandleNavigate('')} className={pathname === "/" ? 'Active' : ''}><IoHomeOutline className='icon' /></p>
                    <p onClick={() => HandleNavigate('/dashboard')} className={pathname === "/" ? 'Active' : ''}><GrUserAdmin className='icon' /></p>
                    <p onClick={() => HandleNavigate('aboutus')} className={pathname === "/" ? 'Active' : ''}><LuFileQuestion className='icon' /></p>
                    <p onClick={() => HandleNavigate('Support')} className={pathname === "/Support" ? 'Active' : ''}><BiSupport className='icon' /></p>
                </div>) : null
                }
            </div>

            <RiLogoutBoxLine className='icon Logout cursor-pointer text-white' onClick={Logout} />

        </aside >

        <span className='bars' onClick={handleHide}>
            {Hide ? <RiCloseCircleLine /> : <FaBars />}
        </span>

    </>
}
